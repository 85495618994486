$breakpoint-max-xxs: 320px;//max covers older iphones
$breakpoint-min-xxs: 321px;//starts above older iphones
$breakpoint-max-xs: 480px;//max covers older androids
$breakpoint-min-xs: 481px;//starts above older androids
$breakpoint-max-sm: 767px;//before tablet
$breakpoint-min-sm: 768px;//start of tablet
$breakpoint-max-md: 1023px;//before tablet landscape
$breakpoint-min-md: 1024px;//starts at tablet landscape, goes into desktop
$breakpoint-max-lg: 1279px;//before bigger deskstops
$breakpoint-min-lg: 1280px;//starts with bigger desktops
$breakpoint-max-xl: 1439px;//before wide screens
$breakpoint-min-xl: 1440px;//wide screens
$breakpoint-max-xxl: 1535px;//before macbook pros
$breakpoint-min-xxl: 1536px;//macbook pro+

//Bootstrap grid sizes
$bootstrap-min-sm-temp: 576px;


//Main Layout Sizes
$max-width-size:991px;
$max-991:991px;
$min-width-size:992px;
$min-992:992px;

.hidden-lg-up {
  @media(min-width:$min-width-size) {
    display:none;
  }
}
.hidden-lg-down {
  @media(max-width:$max-width-size) {
    display:none;
  }
}

//min/max specific sizes

$min-1366: 1366px;
$max-1365: 1365px;

$min-1200:1200px;
$max-1199:1199px;

//Special Layout Sizes
$custom-size-1: 500px;
$custom-size-3: 700px;
$custom-size-5: 800px;
$custom-size-2: 1086px;
$custom-size-4: 1200px;
$custom-size-5: 767px;

//Adjust Break points Here

$max-iphone-6-size:375px;
$min-above-iphone-6:376px;
$mobile-size-1: 455px;
$mobile-size-2: 600px;
$mobile-size-4: 991px;
$mobile-size-3: 1300px;


