﻿/// <reference path="../../base.scss" />
/// GENERAL CONTENT
[data-generalcontent] {
    ul {
        padding-left: $padding-base * 2.5;
    }

    .card__headline {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .card__content {
        padding: $padding-base;
        margin: -$padding-base;
        overflow: auto;
    }
}

.program-read-more {
    position: relative;
    //font-weight: 600;

    &:after {
        content: '';
        position: absolute;
        //top: calc(50% - 3px);
        margin-left: $padding-base * 0.25;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid $link-default;
    }
}


/// GENERAL CARD BLOCK
// putting styles affecting all cards here since its the general one
.card {
    position: relative;
    padding: 0;
    margin: 1px 1px $padding-base * 2;
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.05);

    &.card--square {
        border-radius: 0;
    }

    &.card--clean {
        box-shadow: none;
    }

    .card__footer {
        font-size: 1.2rem;
    }
}

.card__headline {
    padding-top: 0;
}

.card__icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.6rem;
    font-weight: bold;
    width: 100%;
    margin: 0;
    padding: 1.8rem $padding-base $padding-base * 0.5;
    color: #000;

    .fa {
        margin-right: $padding-base * 0.25;
    }
}

.card__image {
    position: relative;
    overflow: hidden;
    width: 100%;

    iframe,
    img {
        width: 100%;
    }

    .card__icon {
        color: #fff;
    }

    @media (max-width: $breakpoint-max-xs) {
        //display: none;
    }
}

.no-image .card__icon {
    position: relative;
    padding-bottom: 0;
}

.card__headline__link {
    color: #000;

    &:visited {
        color: inherit;
    }
}

.card__content {
    margin: 0;
    padding: $padding-base;

    ul,
    ol {
        padding-left: 4rem;
    }

    .card-jobListing__list {
        padding-left: 0;
    }
}

.card__text {
    &:last-child {
        padding-bottom: 0;
    }
}

.card__CTA__wrapper {
    text-align: right;
    position: relative;

    .CTA__link {
        position: relative;
        display: inline-block;
        width: 4.8rem;
        height: 4.8rem;
        background-color: $secondary-blue;
        color: #fff;
        text-align: center;
        vertical-align: bottom;
        transition: $transition-universal;

        &:hover {
            background-color: $cta-arrow-bg-hover;
            color: #fff;
        }

        &:visited {
            color: #fff;
        }
    }

    .CTA__link__span {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
// Ad Block

.ad-block {
    margin-bottom: 3rem;

    &-link {
        display: block;
    }

    img {
        display: block;
    }
    @media(max-width: $breakpoint-max-sm) {
        display:none;
    }
}
/// LANDING HERO BLOCK
[data-landingheroblock] {
    h1 {
        color: #fff;
    }
}

.brand-expression__background {
    background-repeat: repeat;
    background-color: $off-white;

    @media (max-width:$breakpoint-max-md) {
        min-height: 100%;
        background-image: none !important;
        background-color: $primary-blue-1;
    }
}

.brand-expression__cover-image {
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    min-height: 40rem;

    @media (min-width: $breakpoint-min-md) {
        position: relative;
        min-height: 25rem;
        margin-top: $padding-base * 1.5;

        > .row {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            margin: 0;
            transform: translate(0, -50%)
        }
    }

    @media (max-width:$breakpoint-max-md) {
        min-height: 100%;
        background-image: none !important;
    }

    &.page-edit-pseudo {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        margin: 0;
    }
}

.brand-expression__text-container {
    background-color: $primary-blue-1;
    padding: $padding-base;
    margin: $padding-base * 3 0;
    color: #fff;

    @media(min-width: $min-992) {
        width: 50rem;
    }

    @media(max-width: $breakpoint-max-md) {
        padding: $padding-base;
        margin: 0
    }
}


// LANDING GENERAL CARD BLOCK
[data-landinggeneralcard] {
    .card__content {
        padding: 0;
    }

    .program-media-container {
        padding-bottom: $padding-base * 0.5;
    }
}

// PAGINATION
.pagination--search {
    float: left;
    padding-bottom: $padding-base * 2;
}

.pagination__list {
    list-style: none;
    display: inline-flex;
    justify-content: center;
    padding-left: 0;
}

.pagination__number {
    &--active {
        .pagination__link {
            &,
            &:visited {
                background-color: $icon-blue;
                color: #fff;
                pointer-events: none;
                border-color: $icon-blue;
            }
        }
    }

    &:first-child {
        .pagination__link {
            margin-left: 0;
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }
    }

    &:last-child {
        .pagination__link {
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }
    }
}

.pagination__link {
    &,
    &:visited {
        padding: .8rem 1.2rem;
        color: $icon-blue;
        background-color: #fff;
        border: 1px solid $grey-border;
        margin-left: -1px;

        &:hover {
            background-color: #eee;
        }
    }
}

// RIGHT RAIL
[data-rightrail] {
    .sidebar {
        &.card {
            box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, .05);
            ;
            border: 1px solid #ddd;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .card__content {
        padding: 0;
    }
}

.sidebar-ad {
    padding-bottom: $padding-base * 1.5;
}

.sidebar-ad {
    .sidebar-ad__image {
        display: block;
        max-width: 30rem;
        width: 100%;
        height: auto;
        margin-right: auto;
        margin-left: auto;
    }
}

/// RIGHT RAIL LIST BLOCK
[data-rightrail] {
    &.col-lg-3 {
        @media (min-width: $min-992) {
            flex: 0 0 33rem;
            max-width: 33rem;
        }
    }

    .collapse {
        &.in {
            display: block;
        }
    }
}

.sidebar .panel__heading {
    border-top: 3px solid #babf33;
    border-radius: 0;
    color: #333;
    background-color: #f5f5f5;
    padding: $padding-base * 0.5;
    position: relative;
}

.card-sidebar--ads {
    > div {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        text-align: center;
    }
}

.sidebar i {
    color: #babf33
}

.sidebar h4 {
    color: #5e6a71;
}

.panel__title {
    padding: 0;

    i {
        margin-right: $padding-base * 0.25;
    }
}

.panel__body {
    border-top: 1px solid #ddd;
    padding: $padding-base;
    padding-left: $padding-base * 2;
    margin: 0;

    .panel__list {
        list-style: none;
        padding-left: 0;
        margin-bottom:0;
    }
}

.panel__list-item {
    &:not(:last-child) {
        padding-bottom: $padding-base * 0.5;
    }
}

// VIDEO EMBED

.youtube-player {
    position: relative;
    padding-bottom: 56.23%;
    /* Use 75% for 4:3 videos */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin: 5px;
}

.youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
}

.youtube-player img {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    transition: .4s all;
}

.youtube-player img:hover {
    -webkit-filter: brightness(75%);
}

.youtube-player .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    //need to replace this to css isn't making an external call
    //background: url("//i.imgur.com/TxzC70f.png") no-repeat;
    cursor: pointer;
}

.youtube-player {
    //white background for icon
    &:before {
        content: ' ';
        width: 3rem;
        height: 3rem;
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        z-index: 1;
    }

    &:after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24'%3E%3Cpath d='M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z'/%3E%3C/svg%3E");
        //font-size: 5.2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #666;
        pointer-events: none;
        transition: $transition-universal;
        z-index: 1;
    }

    &:hover,
    &:active,
    &:focus {
        &:after {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f00' width='48' height='48' viewBox='0 0 24 24'%3E%3Cpath d='M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z'/%3E%3C/svg%3E");
        }
    }
}

/// ACTUARIAL CLUB

//.actuarial__table {
//    color: $header-text;
//    margin-bottom: 3.2rem;
//    border: 1px solid $secondary-grey;
//    border-collapse: collapse;
//
//    tbody {
//        tr {
//            &:hover {
//                background-color: $table-hover-grey;
//            }
//
//            td {
//                vertical-align: middle;
//            }
//        }
//    }
//
//    .sorting--mobile {
//        display: none;
//        font-weight: 400;
//    }
//
//    @media (min-width: $breakpoint-min-sm) {
//        th {
//            border-bottom: 2px solid #ddd;
//            border-right: 1px solid $secondary-grey;
//        }
//
//        td {
//            border-top: 1px solid #ddd;
//            border-right: 1px solid $secondary-grey;
//
//            .meta--label {
//                display: none;
//            }
//        }
//    }
//
//    @media (max-width: $breakpoint-max-sm) {
//        font-size: 1.2rem;
//
//        thead {
//            background-color: $grey-opaque;
//
//            th {
//                display: inline-block;
//                border: none;
//            }
//
//            .sorting_disabled {
//                display: none;
//            }
//
//            .sorting--mobile {
//                display: inline-block;
//            }
//        }
//
//        tbody {
//            tr {
//                display: block;
//                padding: $padding-base * 1.5 0;
//                box-shadow: inset 0 1px $secondary-grey;
//
//                &:first-child {
//                    box-shadow: 0 -1px $secondary-grey, 0 1px $secondary-grey;
//                }
//
//                td {
//                    padding: 0.2rem $padding-base;
//                    display: block;
//                    border-color: transparent;
//
//                    &:nth-child(4) {
//                        font-weight: bold;
//                    }
//
//                    .meta--label {
//                        font-style: italic;
//                        font-weight: normal;
//                        display: inline-block;
//                        width: 5.6rem;
//                        color: $dark-grey;
//                    }
//                }
//            }
//        }
//    }
//
//    .icon {
//        @media (min-width: $breakpoint-min-sm) {
//            text-align: center;
//        }
//
//        .website--icon {
//            //fill: $icon-blue;
//            height: 2rem;
//            width: 2rem;
//            transition: $transition-universal;
//
//            &:hover {
//                //fill: $link-hover;
//            }
//        }
//
//        i {
//            font-size: 2rem;
//        }
//
//        a[href=""],
//        a:not([href]) {
//            pointer-events: none;
//            cursor: default;
//
//            .website--icon {
//                //fill: $light-icons;
//            }
//
//            i {
//                //color: $light-icons;
//            }
//        }
//
//        @media (max-width: $breakpoint-max-sm) {
//            .website--icon {
//                height: 18px;
//                width: 18px;
//            }
//
//            i {
//                font-size: 1.8rem;
//            }
//        }
//    }
//}

/// ADVANCED SEARCH

.advanced__item {
    display: flex;
    padding-bottom: $padding-base * 0.5;

    .advanced__text {
        flex: 0 0 30%;
        max-width: 30%;

        @media(max-width:$breakpoint-max-sm) {
            flex: 0 1 auto;
            max-width: none;
            padding-bottom: $padding-base * 0.5;
        }
    }

    .advanced__label {
        flex: 0 0 70%;
        max-width: 70%;

        @media(max-width:$breakpoint-max-sm) {
            flex: 0 1 auto;
            max-width: none;
        }
    }

    .advanced__input {
        width: 100%;
        height: 3.4rem;
        padding: $padding-base * 0.5 $padding-base;
        line-height: 1.5;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid $grey-border;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(#000, .075);
        font-size: 1.4rem;
    }

    @media(max-width:$breakpoint-max-sm) {
        flex-direction: column;
    }
}

.datepicker {
    display: flex;
    align-items: center;

    @media(max-width:$breakpoint-max-sm) {
        flex-direction: column;
        align-items: flex-start;
    }

    span {
        padding: $padding-base * 0.5;
    }

    .advanced__input {
        @media(max-width:$breakpoint-max-sm) {
            width: 16rem;
        }
    }
}

.advanced__search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-bar__textbox {
    margin-right: $padding-base * 0.5;
}

.search-author__button {
    height: 100%;
    padding-top: .6rem;
    padding-bottom: .6rem;
}

.search--author {
    padding: $padding-base * 0.5 0;
}

.authors__table {
    width: 100%;
    flex: 0 0 100%;
}

.authors__result-links {
    float: right;
}

.authors__link {
    width: 30%;
}

.authors__info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    display: none;
}

.advanced__button-group {
    padding: $padding-base * 0.5 0;
    text-align: center;

    .advanced__button {
        //padding: .6rem 1.2rem;
        margin-right: $padding-base * 0.5;
    }

    @media(max-width:$breakpoint-max-sm) {
        text-align: left;
    }
}

// customize datepicker

span.flatpickr-weekday {
    background: $primary-red;
    color: #fff;
}

.flatpickr-weekdays {
    background: $primary-red;
}

.flatpickr-months .flatpickr-month {
    background: $primary-red;
    color: #fff;
}

/// BROWSE BY TOPIC

[data-browsebytopicpage] {
    .card__list {
        padding-top: 2.4rem;

        &--item {
            padding-bottom: 1.6rem;

            ul {
                padding-top: 0;
                padding-bottom: 0;
            }

            li {
                &:first-child {
                    padding-top: .8rem;
                }
            }

            .collapse-icon {
                font-size: 2.4rem;
                line-height: 1;
                color: $link-default;

                a {
                    &:visited {
                        color: $link-default;
                    }
                }
            }
        }
    }
}

//// COLLEGE LISTING

.college-listing__headline {
    padding-bottom: $padding-base * 3;
}

.college-listing__button {
    padding: $padding-base * 0.125 $padding-base;
}

.college-institution__contact {
    padding-bottom: $padding-base * 0.5;
}

.college-institution__urls {
    display: flex;
    flex-direction: column;
    padding-bottom: $padding-base * 2;
}

/// EXAM
.registration__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding-bottom: $padding-base;

    @media (max-width: $breakpoint-max-sm) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.registration-select__wrapper {
    width: 65%;
    padding-right: $padding-base;

    @media (max-width: $breakpoint-max-sm) {
        width: 95%;
        padding-right: 0;
        padding-bottom: $padding-base;
    }
}

.registration-select__bar {
    width: 100%;
}

/// FACETS
[data-facets] {
    .card__content {
        padding: 0;
    }
}

.facets__icon {
    color: $primary-blue-1;
    font-weight: 700;
    font-size: 2rem;
    padding-bottom: $padding-base;
}

.reset__link {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $padding-base * 0.5;
    ;
}

.facet-caret {
    margin-left: $padding-base * 0.25;
}

// INTERIOR PAGE
//unused
// [data-interiorpage] {
//   h2,h3,h4,h5,h6 {
//     padding-top: $padding-base * 1.5;
//   }

//   ol,ul,p,h2,h3,h4,h5,h6 {
//     padding-bottom: $padding-base;
//   }

//   .panel__title {
//     padding: 0;
//   }

//   .card {
// 		border: none;
//     border-radius: 0;
//     box-shadow: none;
//   }

// }

/// LANDING PAGE
[data-landingpage] {
    .card {
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
}

.secondary-content .program-landing-card {
    border-top: none;
    padding: $padding-base $padding-base * 2 $padding-base * 3 $padding-base * 2;
    background-color: #fff;
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, .05);
    ;
}

.program-landing__optional-top-article {
    padding-bottom: $padding-base * 2.5;
}

.program-landing-card__headline,
.program-landing__lower-grid-article {
    padding-top: $padding-base * 2.5;
}

.secondary-content--program {
    article {
        & + article {
            border-top: 1px solid #eee;
        }
    }
}

.program-landing__top-left-content {
    blockquote {
        &,
        span {
            font-size: 2rem;
        }
    }
}


// LOGIN PAGE

[data-loginpage] {
    padding-top: $padding-base * 5;
    background: #fff;

    form {
        label {
            display: block;
        }
    }

    .card {
        .form {
            padding: 0 $padding-base;
        }
    }

    .login__register {
        .card__footer {
            ul, ol {
                list-style-position: inside;
                padding-left: 0;
            }

            @media(max-width:$breakpoint-max-sm) {
                ul, ol {
                    column-count: 2;
                }
            }

            @media(min-width:$breakpoint-min-md) {
                ul, ol {
                    column-count: 2;
                }
            }
        }
    }

    .login__headline {
        padding-bottom: $padding-base * 1.5;
    }

    .login__label {
        font-weight: 700;
    }

    .login__input {
        font-size: 1.6rem
    }

    .form-error,
    .validation-summary-errors {
        color: $link-red;
    }

    .form-group--forgot,
    .form-group--button {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }

    .alert__icon {
        font-size: 3rem;
        color: #c71d28;
        padding-top: 1rem;
    }
}

/// PUBLICATION BROWSE

[data-publicationbrowsepage] {
    .pub {
        padding-bottom: 1.6rem;
    }

    .button--pub {
        margin: 0 $padding-base * 0.5;
    }
}

.pubBrowse__year--list {
    .select--all {
        display: block;
    }

    .select--year {
        display: inline-block;
        float: left;
        padding-right: 2.4rem;
    }
}

//from multiple-select.css
.ms-parent {
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.ms-choice {
    display: block;
    width: 100%;
    height: 26px;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #aaa;
    text-align: left;
    white-space: nowrap;
    line-height: 26px;
    color: #444;
    text-decoration: none;
    border-radius: 4px;
    background-color: #fff;

    &.disabled {
        background-color: #f4f4f4;
        background-image: none;
        border: 1px solid #ddd;
        cursor: default
    }

    > span {
        position: absolute;
        top: 0;
        left: 0;
        right: 2rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding-left: $padding-base * 0.5;

        &.placeholder {
            color: #000000;
        }

        > div {
            position: absolute;
            top: 0;
            right: 0;
            width: 2rem;
            height: 2.5rem;
        }
    }
}

.ms-drop {
    width: 100%;
    overflow: hidden;
    margin-top: -1px;
    padding: 0;
    display: none;
    position: absolute;
    z-index: 1000;
    background: #fff;
    color: #000;
    border: 1px solid #aaa;
    border-radius: 4px;

    .ms-no-results {
        display: none;
    }

    input[type="checkbox"] {
        vertical-align: middle;
    }

    &.bottom {
        top: 100%;
        box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
    }

    &.top {
        bottom: 100%;
        box-shadow: 0 -4px 5px rgba(0, 0, 0, .15);
    }

    ul {
        overflow: auto;
        margin: 0;
        padding: 5px 8px;

        > li {
            list-style: none;
            display: list-item;
            background-image: none;
            position: static;

            label {
                font-weight: normal;
                display: block;
                white-space: nowrap;

                .optgroup {
                    font-weight: bold;
                }
            }

            &.disabled {
                opacity: .35;
                filter: Alpha(Opacity=35);
            }

            &.multiple {
                display: block;
                float: left;
            }

            &.group {
                clear: both;
            }

            &.multiple {
                label {
                    width: 100%;
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.ms-search {
    display: inline-block;
    margin: 0;
    min-height: 26px;
    padding: 4px;
    position: relative;
    white-space: nowrap;
    width: 100%;

    input {
        width: 100%;
        height: auto !important;
        min-height: 2.4rem;
        padding: 0 2rem 0 .5rem;
        margin: 0;
        outline: 0;
        font-family: sans-serif;
        font-size: 1em;
        border: 1px solid #aaa;
        border-radius: 0;
        box-shadow: none;
    }

    &,
    input {
        box-sizing: border-box;
    }
}

.close-multiselect {
    position: absolute;
    top: 0;
    right: .8rem;
}

/// PD CALENDER

[data-pdcalendarpage] {
    .facets__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .facets__headline {
        display: inline-block;
        padding: 1rem 0;
        color: $primary-blue-1;
    }

    .facet--group {
        border-top: 1px solid #ddd;

        &:last-child {
            border-bottom: 1px solid #ddd;
        }
    }

    .facet__header {
        position: relative;

        &:after {
            font-family: 'Font Awesome 6 Free';
            content: "\f067";
            position: absolute;
            right: 0.9rem;
            top: calc(50% - 0.9rem);
            user-select: none;
            cursor: pointer;
            font-weight: 900;
        }

        &:not(.collapsed) {
            &:after {
                content: '\f068';
            }
        }

        &:hover {
            .facet__headline {
                color: $primary-blue-1;
            }
        }
    }

    .facet__headline {
        padding: $padding-base 0;
        color: $link-default;

        @media(max-width: $max-1199) {
            font-size: 1.6rem;
            padding: $padding-base * .75 0;
        }
    }

    .facet__list {
        padding: .6rem .6rem $padding-base;

        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: .6rem;
        }
    }
}

.card--pdCalendar {
    @media(max-width: $max-991) {
        background-color: transparent;
        box-shadow: none;
        border: none;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }
}

.catalog__view--grid {
    .catalog__item {
        @media(min-width:$bootstrap-min-sm-temp) {
            &:nth-child(odd) {
                padding-right: 1rem;
            }

            &:nth-child(even) {
                padding-left: 1rem;
            }
        }
    }

    .catalog__item--card {
        height: calc(100% - 2rem);

        &:before {
            content: '';
            height: 5px;
            position: absolute;
            left: -1px;
            right: -1px;
            top: -1px;
        }

        &.card-info {
            &:before {
                background-color: $secondary-blue;
            }
        }

        &.card-primary {
            &:before {
                background-color: $primary-blue-1;
            }
        }

        &.card-danger {
            &:before {
                background-color: $primary-red;
            }
        }

        &.card-warning {
            &:before {
                background-color: $primary-yellow;
            }
        }

        &.card-secondary {
            &:before {
                background-color: $secondary-teal;
            }
        }

        @media (max-width: $breakpoint-max-sm) {
            &.card-info,
            &.card-primary,
            &.card-danger,
            &.card-warning,
            &.card-secondary
            {
                &:before {
                    background: none;
                }
            }
        }
    }

    .catalog__item--link {
        height: 100%;
    }

    .card__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 14.6rem;
    }

    .catalog__item--icon {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}

.catalog__view--list {
    .catalog__item {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }

    .card__content {
        display: flex;
        padding-top: $padding-base * 1.5;

        @media(max-width: $breakpoint-max-sm) {
            flex-direction: column;
        }
    }

    .card__headline {
        width: 66.6%;
        padding-right: $padding-base;
    }

    .catalog__item--meta {
        width: 33.3%;
        justify-content: space-between;

        @media(max-width: $breakpoint-max-sm) {
            width: 100%;
        }
    }

    .catalog__item--icon {
        padding-left: 1.6rem;
        margin-left: auto;
        text-align: right;
        padding-right: 0;
    }
}

.catalog__item--icon {
    img {
        max-width: 4.8rem;
    }
}

.catalog__results {
    .card {
        margin-bottom: 2rem;
    }
}

.catalog__controls {
    padding-bottom: 1.6rem;
    padding-top: 3.2rem;

    .fas {
        font-size: 2.2rem;
        color: $dark-icons;
        transition: $transition-universal;

        &:hover {
            color: $light-icons;
        }
    }

    .active {
        .fas {
            color: $light-icons;
        }
    }

    @media(max-width: $max-1199) {
        padding-top: 0;
    }
}

.catalog__item--location {
    color: $dark-grey;

    p {
        padding: 0;
    }
}

.catalog__item--link {
    &:hover {
        background-color: $off-white;
    }
}

.pdCaledarTabs {
    ul.nav-tabs {
        padding-left: 0;

        .nav-link {
            border-radius: 0;
            padding: 1rem 1.6rem;
            margin-bottom: -1px;
            border: 1px solid transparent;

            &.active {
                border: 1px solid $grey-border;
                border-top: 2px solid $primary-blue-1;
                border-bottom: 1px solid #fff;

                a {
                    color: $body-copy-color;
                }

                &:hover {
                    border-top: 2px solid $tab-hover;
                }
            }

            &:hover {
                border-color: none;
            }
        }
    }
}

/// EVENT DETAIL
[data-event-detail] {
    .banner-col-lg {
        @media(min-width: $min-1200) {
            max-width: 87.5rem;
        }
    }

    .event-panel__filter {
        box-shadow: none;
        border: 0 none;
        background: transparent;
        margin-bottom: 0;
        border-bottom: 1px solid #ddd;
    }

    .banner-col-sm {
        @media(min-width: $min-1200) {
            width: calc(100% - 87.5rem);
        }
    }

    .content-col-lg {
        @media(min-width: $min-1200) {
            //bs override
            width: 84.4rem;
            max-width: 84.4rem;
            flex: 0 0 84.4rem;
        }
    }

    .content-col-sm {
        @media(min-width: $min-1200) {
            width: calc(100% - 84.4rem);
            max-width: calc(100% - 84.4rem);
            flex: 0 0 calc(100% - 84.4rem);
        }
    }

    .nav-tabs {
        border-bottom: none;
    }

    .interior-nav__link {
        span {
            pointer-events: none;
        }
    }
}

.product-event__no-margin {
    padding-bottom: 0;
}

.banner--event-detail {
    padding-top: $padding-base * 2;
}

.brand__wrapper--event {
    padding-top: $padding-base;
}

.banner {
    width: 100%;
    background-color: #fff;
}

.banner__info {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 $padding-base;
    width: 100%;

    .banner__title {
        font-size: 3.2rem;
        padding-bottom: 0;
        color: #fff;
        padding: 0;
    }

    .banner__subtitle {
        color: #fff;
        padding: 0;
        padding-bottom: .4rem;
        font-size: 2rem;
    }

    @media (max-width: $max-991) {
        padding: $padding-base * 2 $padding-base * 1.5;
    }
}

.banner__register {
    color: $primary-red;
    text-align: center;
    width: 100%;
    display: block;

    @media (max-width: $max-991) {
        padding: $padding-base * 1.5 0;
    }
}

.banner__buttonWrapper {
    padding-bottom: $padding-base * 0.5;
}

.toggle-show {
    &-container {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: .8rem;
        margin-top: 0;

        @media (max-width: $max-991) {
            max-width: 300px;
        }
    }

    &-trigger {
        width: 100%;
        display: inline-flex;
        justify-content: space-between;
        font-weight: normal;
        outline: none;

        i {
            margin-left: .6rem;
            transition: .2s all;
        }

        &[aria-expanded="true"] {
            i {
                transform: rotate(180deg);
            }
        }

        &:active, &:focus {
            outline: none;
        }
    }

    &-panel {
        position: absolute;
        background: #ffffff;
        z-index: 10;
        width: calc(100% - 30px);
        display: none;

        &[aria-hidden="false"] {
            display: block;
            box-shadow: 2px 2px 8px 0px rgba(50, 50, 50, 0.75);
        }

        .register-list {
            list-style: none;
            padding: 0;

            &-item {
                a {
                    padding: .8rem;
                    display: block;
                    color: #666;

                    &:hover {
                        background-color: #f2f2f2;
                    }
                }
            }
        }
    }
}


//.button {
//    &.banner__button,
//    &.banner__button:visited {
//        background-color: $primary-red !important;
//        border-color: $primary-red !important;
//        color: #fff !important;
//        font-weight: 400;
//        padding-left: 3.2rem;
//        padding-right: 3.2rem;
//        border-width: 2px !important;
//        border-radius: 4px !important;
//
//        &:hover {
//            background-color: #fff !important;
//            color: $primary-red !important;
//        }
//    }
//}

.banner-col-lg {
    display: flex;
    align-items: center;
}

.banner-col-sm {
    display: flex;
    align-items: center;

    @media (max-width: $max-991) {
        display: block;
    }
}

.banner__bg {
    min-height: 15rem;
    display: flex;
    align-items: center;
    width: 100%;
    background-size: cover;

    @media (max-width: $max-991) {
        background-image: none !important;
        background-color: $primary-blue-1;
    }

    @media (min-width: $min-992) {
        height: 1px;
    }
}

.product-event {
    background-color: #fff;
}

.product-event__card {
    padding: $padding-base * 2 $padding-base * 2 $padding-base * 3;

    .tab-content {
        ul {
            padding-left: 4rem;
        }
    }
}

.product-event__competency {
    display: inline-block;
    list-style: none;
    padding-left: 0;
}

.product-event__competencyItem {
    display: inline;
}

.product-event__competencyImg {
    padding: 0 $padding-base * 0.25 $padding-base * 0.25
}

//sidebar
.product-event__sidebar {
    padding-top: $padding-base * 4.5;
    padding-bottom: $padding-base * 4;

    @media (max-width: $max-991) {
        padding: 0 $padding-base * 2 $padding-base;
    }
}

.product-event__list {
    list-style: none;
    padding: $padding-base $padding-base $padding-base * 4 0;

    @media (max-width: $max-991) {
        padding-bottom: $padding-base * 2;
    }

    &:not(first-of-type) {
        padding-top: 0;
    }
}

.product-event__item {
    padding: $padding-base 0;
    border-bottom: 1px solid $grey-border;

    &:first-of-type {
        padding-top: 0;
    }
}

.product-event__listHeadline {
    padding-top: 1rem;
    text-transform: uppercase;
    color: $body-copy-color;
}

.product-event__panel {
    margin-bottom: 1.2rem;
    padding: 0.4rem 1.2rem 0.8rem;
    border: 0.1rem solid #ddd;
}

.session-link {
    &,
    &:visited {
        color: $link-default;
    }
}

.soa-sidebar-filter__header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


a.resetAllFilters:not([href]) {
    color: $link-default;
}

.soa-sidebar-filter__headline {
    padding: 0;
    color: $primary-blue-1;
}

.event-panel__button {
    display: block;
    border: 0 none;
    box-shadow: none;
    border: 0 none;
    background: transparent;
    padding: .8rem 0;
    text-align: left;
    width: 100%;
    position: relative;

    &:hover {
        .event-panel__title {
            color: $link-hover;
        }
    }

    &:after {
        font-family: 'Font Awesome 6 Free';
        content: "\f067";
        position: absolute;
        right: 0.9rem;
        top: calc(50% - 0.9rem);
        user-select: none;
        cursor: pointer;
        font-weight: 900;
        color: $body-copy-color;
    }

    &:not(.collapsed) {
        &:after {
            content: '\f068';
        }
    }
}

.event-panel__title {
    font-size: 1.8rem;
    padding-top: 0;
    color: $link-default;
}

.event-panel__body {
    padding-left: 0;
    list-style: none;
}

.filter__label {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
}

.filter__checkbox {
    flex-shrink: 0;
}
//modal bs overrides
.modal--sessions {
    .modal-title {
        padding-top: 0;
        padding-bottom: 0;
    }

    .modal-body,
    .modal-header {
        padding: 2rem;
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 60rem;
        }
    }
}


/// INTERIOR NAV

.soa-program-interior-nav {
    @media (max-width: $max-991) {
        border-left: 0.1rem solid #bdbaba;
        border-right: 0.1rem solid #bdbaba
    }
}

.interior-nav__list {
    display: flex;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    background: #fff;

    @media (max-width: $max-991) {
        display: block;
    }
}

$soa-blue:#00538e;
$soa-blue-darker:#004272;
$soa-blue-darkest:#00192b;
$soa-blue-lighter:#196499;
$soa-blue-light:#f2f6f9;

.interior-nav__item {
    position: relative;
    background: #f8f8f8;
    text-align: center;
    flex: 1 0 auto;
    transition: all .3s ease;

    &:hover {
        background: $soa-blue-lighter;
    }

    &:hover .interior-nav__link {
        color: #fff;
        transition: none
    }

    &.active,
    &.active-program-page {
        background: $soa-blue !important;
        transition: all .3s ease;

        .interior-nav__link {
            color: #fff;
            font-weight: 700;
        }

        &:hover {
            background: $soa-blue-lighter !important;

            &:after {
                border-top-color: $soa-blue-lighter;
            }
        }

        &:after {
            @media (min-width: $min-992) {
                content: '';
                position: absolute;
                top: 100%;
                left: calc(50% - .8rem);
                width: 0;
                height: 0;
                margin-top: -1px;
                border-left: .8rem solid transparent;
                border-right: .8rem solid transparent;
                border-top: .8rem solid $soa-blue;
                z-index: 1;
                transition: all .3s ease
            }
        }
    }

    &:nth-last-child(-n+1):first-child,
    &:nth-last-child(-n+1):first-child ~ li {
        display: none;
    }

    @media (min-width: $min-992) {
        &:nth-last-child(n+2):nth-last-child(-n+2):first-child,
        &:nth-last-child(n+2):nth-last-child(-n+2):first-child ~ li {
            width: 49.5%;
        }

        &:nth-last-child(n+3):nth-last-child(-n+3):first-child,
        &:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ li {
            width: 33%;
        }

        &:nth-last-child(n+4):nth-last-child(-n+4):first-child,
        &:nth-last-child(n+4):nth-last-child(-n+4):first-child ~ li {
            width: 24.5%;
        }

        &:nth-last-child(n+5):nth-last-child(-n+5):first-child,
        &:nth-last-child(n+5):nth-last-child(-n+5):first-child ~ li {
            width: 19.5%;
        }
    }

    @media (max-width: $max-991) {
        display: block;
        width: 100%;
        border-bottom: 0.1rem solid #bdbaba;
        background: #fff;

        &:first-child {
            border-top: 0.1rem solid #bdbaba;
        }

        & .interior-nav__link {
            min-height: 2rem;
        }
    }
}

.interior-nav__link,
.interior-nav__link:visited {
    display: flex;
    position: relative;
    min-height: 4.8rem;
    color: $soa-blue;
    transition: $transition-universal;
    justify-content: center;
    align-items: center;
    // IE11 fix for vertical alignment
    height: 1px;

    &.active {
        background: $soa-blue !important;
        color: #fff;
        font-weight: 700;

        &:after {
            @media (min-width: $min-992) {
                content: '';
                position: absolute;
                top: 100%;
                left: calc(50% - .8rem);
                width: 0;
                height: 0;
                margin-top: -1px;
                border-left: .8rem solid transparent;
                border-right: .8rem solid transparent;
                border-top: .8rem solid $soa-blue;
                z-index: 1;
                transition: all .3s ease
            }
        }
    }

    @media (max-width: $max-991) {
        padding: $padding-base * 0.5;
    }
}