@media (min-width: 992px) {
  .hidden-lg-up {
    display: none;
  }
}

@media (max-width: 991px) {
  .hidden-lg-down {
    display: none;
  }
}

.main a:not(.results__link):not(.card__headline__link)[href$=".pdf"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".doc"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".docx"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xls"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xlsx"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xlsm"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".ppt"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".pptx"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".zip"]:after {
  padding-left: 0.4em;
  font-size: 0.9em;
  display: inline-block;
  transform: translateY(-0.1em);
  font-family: "Font Awesome 6 Free";
  font-weight: 400;
}
.main a:not(.results__link):not(.card__headline__link)[href$=".pdf"]:after {
  content: "\f1c1";
}
.main a:not(.results__link):not(.card__headline__link)[href$=".doc"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".docx"]:after {
  content: "\f1c2";
}
.main a:not(.results__link):not(.card__headline__link)[href$=".xls"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xlsm"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".xlsx"]:after {
  content: "\f1c3";
}
.main a:not(.results__link):not(.card__headline__link)[href$=".ppt"]:after, .main a:not(.results__link):not(.card__headline__link)[href$=".pptx"]:after {
  content: "\f1c4";
}
.main a:not(.results__link):not(.card__headline__link)[href$=".zip"]:after {
  content: "\f1c6";
}
.main .soa-sidebar-content a[href$=".pdf"]:after, .main .soa-sidebar-content a[href$=".doc"]:after, .main .soa-sidebar-content a[href$=".docx"]:after, .main .soa-sidebar-content a[href$=".xls"]:after, .main .soa-sidebar-content a[href$=".xlsx"]:after, .main .soa-sidebar-content a[href$=".xlsm"]:after, .main .soa-sidebar-content a[href$=".ppt"]:after, .main .soa-sidebar-content a[href$=".pptx"]:after, .main .soa-sidebar-content a[href$=".zip"]:after {
  display: none !important;
}

[data-generalcontent] ul {
  padding-left: 4rem;
}
[data-generalcontent] .card__headline {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
[data-generalcontent] .card__content {
  padding: 1.6rem;
  margin: -1.6rem;
  overflow: auto;
}

.program-read-more {
  position: relative;
}
.program-read-more:after {
  content: "";
  position: absolute;
  margin-left: 0.4rem;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid #0090bc;
}

.card {
  position: relative;
  padding: 0;
  margin: 1px 1px 3.2rem;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.05);
}
.card.card--square {
  border-radius: 0;
}
.card.card--clean {
  box-shadow: none;
}
.card .card__footer {
  font-size: 1.2rem;
}

.card__headline {
  padding-top: 0;
}

.card__icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.6rem;
  font-weight: bold;
  width: 100%;
  margin: 0;
  padding: 1.8rem 1.6rem 0.8rem;
  color: #000;
}
.card__icon .fa {
  margin-right: 0.4rem;
}

.card__image {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.card__image iframe,
.card__image img {
  width: 100%;
}
.card__image .card__icon {
  color: #fff;
}
.no-image .card__icon {
  position: relative;
  padding-bottom: 0;
}

.card__headline__link {
  color: #000;
}
.card__headline__link:visited {
  color: inherit;
}

.card__content {
  margin: 0;
  padding: 1.6rem;
}
.card__content ul,
.card__content ol {
  padding-left: 4rem;
}
.card__content .card-jobListing__list {
  padding-left: 0;
}

.card__text:last-child {
  padding-bottom: 0;
}

.card__CTA__wrapper {
  text-align: right;
  position: relative;
}
.card__CTA__wrapper .CTA__link {
  position: relative;
  display: inline-block;
  width: 4.8rem;
  height: 4.8rem;
  background-color: #76C3D4;
  color: #fff;
  text-align: center;
  vertical-align: bottom;
  transition: all 0.5s ease;
}
.card__CTA__wrapper .CTA__link:hover {
  background-color: #247893;
  color: #fff;
}
.card__CTA__wrapper .CTA__link:visited {
  color: #fff;
}
.card__CTA__wrapper .CTA__link__span {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ad-block {
  margin-bottom: 3rem;
}
.ad-block-link {
  display: block;
}
.ad-block img {
  display: block;
}
@media (max-width: 767px) {
  .ad-block {
    display: none;
  }
}

[data-landingheroblock] h1 {
  color: #fff;
}

.brand-expression__background {
  background-repeat: repeat;
  background-color: #f2f2f2;
}
@media (max-width: 1023px) {
  .brand-expression__background {
    min-height: 100%;
    background-image: none !important;
    background-color: #024c7b;
  }
}

.brand-expression__cover-image {
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  min-height: 40rem;
}
@media (min-width: 1024px) {
  .brand-expression__cover-image {
    position: relative;
    min-height: 25rem;
    margin-top: 2.4rem;
  }
  .brand-expression__cover-image > .row {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    margin: 0;
    transform: translate(0, -50%);
  }
}
@media (max-width: 1023px) {
  .brand-expression__cover-image {
    min-height: 100%;
    background-image: none !important;
  }
}
.brand-expression__cover-image.page-edit-pseudo {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0;
}

.brand-expression__text-container {
  background-color: #024c7b;
  padding: 1.6rem;
  margin: 4.8rem 0;
  color: #fff;
}
@media (min-width: 992px) {
  .brand-expression__text-container {
    width: 50rem;
  }
}
@media (max-width: 1023px) {
  .brand-expression__text-container {
    padding: 1.6rem;
    margin: 0;
  }
}

[data-landinggeneralcard] .card__content {
  padding: 0;
}
[data-landinggeneralcard] .program-media-container {
  padding-bottom: 0.8rem;
}

.pagination--search {
  float: left;
  padding-bottom: 3.2rem;
}

.pagination__list {
  list-style: none;
  display: inline-flex;
  justify-content: center;
  padding-left: 0;
}

.pagination__number--active .pagination__link, .pagination__number--active .pagination__link:visited {
  background-color: #348ac7;
  color: #fff;
  pointer-events: none;
  border-color: #348ac7;
}
.pagination__number:first-child .pagination__link {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination__number:last-child .pagination__link {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.pagination__link, .pagination__link:visited {
  padding: 0.8rem 1.2rem;
  color: #348ac7;
  background-color: #fff;
  border: 1px solid #ccc;
  margin-left: -1px;
}
.pagination__link:hover, .pagination__link:visited:hover {
  background-color: #eee;
}

[data-rightrail] .sidebar.card {
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin-left: 0;
  margin-right: 0;
}
[data-rightrail] .card__content {
  padding: 0;
}

.sidebar-ad {
  padding-bottom: 2.4rem;
}

.sidebar-ad .sidebar-ad__image {
  display: block;
  max-width: 30rem;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 992px) {
  [data-rightrail].col-lg-3 {
    flex: 0 0 33rem;
    max-width: 33rem;
  }
}
[data-rightrail] .collapse.in {
  display: block;
}

.sidebar .panel__heading {
  border-top: 3px solid #babf33;
  border-radius: 0;
  color: #333;
  background-color: #f5f5f5;
  padding: 0.8rem;
  position: relative;
}

.card-sidebar--ads > div {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: center;
}

.sidebar i {
  color: #babf33;
}

.sidebar h4 {
  color: #5e6a71;
}

.panel__title {
  padding: 0;
}
.panel__title i {
  margin-right: 0.4rem;
}

.panel__body {
  border-top: 1px solid #ddd;
  padding: 1.6rem;
  padding-left: 3.2rem;
  margin: 0;
}
.panel__body .panel__list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.panel__list-item:not(:last-child) {
  padding-bottom: 0.8rem;
}

.youtube-player {
  position: relative;
  padding-bottom: 56.23%;
  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 5px;
}

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent;
}

.youtube-player img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  transition: 0.4s all;
}

.youtube-player img:hover {
  -webkit-filter: brightness(75%);
}

.youtube-player .play {
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  position: absolute;
  cursor: pointer;
}

.youtube-player:before {
  content: " ";
  width: 3rem;
  height: 3rem;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1;
}
.youtube-player:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24'%3E%3Cpath d='M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z'/%3E%3C/svg%3E");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  pointer-events: none;
  transition: all 0.5s ease;
  z-index: 1;
}
.youtube-player:hover:after, .youtube-player:active:after, .youtube-player:focus:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f00' width='48' height='48' viewBox='0 0 24 24'%3E%3Cpath d='M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z'/%3E%3C/svg%3E");
}

.advanced__item {
  display: flex;
  padding-bottom: 0.8rem;
}
.advanced__item .advanced__text {
  flex: 0 0 30%;
  max-width: 30%;
}
@media (max-width: 767px) {
  .advanced__item .advanced__text {
    flex: 0 1 auto;
    max-width: none;
    padding-bottom: 0.8rem;
  }
}
.advanced__item .advanced__label {
  flex: 0 0 70%;
  max-width: 70%;
}
@media (max-width: 767px) {
  .advanced__item .advanced__label {
    flex: 0 1 auto;
    max-width: none;
  }
}
.advanced__item .advanced__input {
  width: 100%;
  height: 3.4rem;
  padding: 0.8rem 1.6rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  font-size: 1.4rem;
}
@media (max-width: 767px) {
  .advanced__item {
    flex-direction: column;
  }
}

.datepicker {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .datepicker {
    flex-direction: column;
    align-items: flex-start;
  }
}
.datepicker span {
  padding: 0.8rem;
}
@media (max-width: 767px) {
  .datepicker .advanced__input {
    width: 16rem;
  }
}

.advanced__search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-bar__textbox {
  margin-right: 0.8rem;
}

.search-author__button {
  height: 100%;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.search--author {
  padding: 0.8rem 0;
}

.authors__table {
  width: 100%;
  flex: 0 0 100%;
}

.authors__result-links {
  float: right;
}

.authors__link {
  width: 30%;
}

.authors__info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  display: none;
}

.advanced__button-group {
  padding: 0.8rem 0;
  text-align: center;
}
.advanced__button-group .advanced__button {
  margin-right: 0.8rem;
}
@media (max-width: 767px) {
  .advanced__button-group {
    text-align: left;
  }
}

span.flatpickr-weekday {
  background: #d13138;
  color: #fff;
}

.flatpickr-weekdays {
  background: #d13138;
}

.flatpickr-months .flatpickr-month {
  background: #d13138;
  color: #fff;
}

[data-browsebytopicpage] .card__list {
  padding-top: 2.4rem;
}
[data-browsebytopicpage] .card__list--item {
  padding-bottom: 1.6rem;
}
[data-browsebytopicpage] .card__list--item ul {
  padding-top: 0;
  padding-bottom: 0;
}
[data-browsebytopicpage] .card__list--item li:first-child {
  padding-top: 0.8rem;
}
[data-browsebytopicpage] .card__list--item .collapse-icon {
  font-size: 2.4rem;
  line-height: 1;
  color: #0090bc;
}
[data-browsebytopicpage] .card__list--item .collapse-icon a:visited {
  color: #0090bc;
}

.college-listing__headline {
  padding-bottom: 4.8rem;
}

.college-listing__button {
  padding: 0.2rem 1.6rem;
}

.college-institution__contact {
  padding-bottom: 0.8rem;
}

.college-institution__urls {
  display: flex;
  flex-direction: column;
  padding-bottom: 3.2rem;
}

.registration__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1.6rem;
}
@media (max-width: 767px) {
  .registration__wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

.registration-select__wrapper {
  width: 65%;
  padding-right: 1.6rem;
}
@media (max-width: 767px) {
  .registration-select__wrapper {
    width: 95%;
    padding-right: 0;
    padding-bottom: 1.6rem;
  }
}

.registration-select__bar {
  width: 100%;
}

[data-facets] .card__content {
  padding: 0;
}

.facets__icon {
  color: #024c7b;
  font-weight: 700;
  font-size: 2rem;
  padding-bottom: 1.6rem;
}

.reset__link {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.8rem;
}

.facet-caret {
  margin-left: 0.4rem;
}

[data-landingpage] .card {
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.secondary-content .program-landing-card {
  border-top: none;
  padding: 1.6rem 3.2rem 4.8rem 3.2rem;
  background-color: #fff;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.05);
}

.program-landing__optional-top-article {
  padding-bottom: 4rem;
}

.program-landing-card__headline,
.program-landing__lower-grid-article {
  padding-top: 4rem;
}

.secondary-content--program article + article {
  border-top: 1px solid #eee;
}

.program-landing__top-left-content blockquote,
.program-landing__top-left-content blockquote span {
  font-size: 2rem;
}

[data-loginpage] {
  padding-top: 8rem;
  background: #fff;
}
[data-loginpage] form label {
  display: block;
}
[data-loginpage] .card .form {
  padding: 0 1.6rem;
}
[data-loginpage] .login__register .card__footer ul, [data-loginpage] .login__register .card__footer ol {
  list-style-position: inside;
  padding-left: 0;
}
@media (max-width: 767px) {
  [data-loginpage] .login__register .card__footer ul, [data-loginpage] .login__register .card__footer ol {
    column-count: 2;
  }
}
@media (min-width: 1024px) {
  [data-loginpage] .login__register .card__footer ul, [data-loginpage] .login__register .card__footer ol {
    column-count: 2;
  }
}
[data-loginpage] .login__headline {
  padding-bottom: 2.4rem;
}
[data-loginpage] .login__label {
  font-weight: 700;
}
[data-loginpage] .login__input {
  font-size: 1.6rem;
}
[data-loginpage] .form-error,
[data-loginpage] .validation-summary-errors {
  color: #c71d28;
}
[data-loginpage] .form-group--forgot,
[data-loginpage] .form-group--button {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}
[data-loginpage] .alert__icon {
  font-size: 3rem;
  color: #c71d28;
  padding-top: 1rem;
}

[data-publicationbrowsepage] .pub {
  padding-bottom: 1.6rem;
}
[data-publicationbrowsepage] .button--pub {
  margin: 0 0.8rem;
}

.pubBrowse__year--list .select--all {
  display: block;
}
.pubBrowse__year--list .select--year {
  display: inline-block;
  float: left;
  padding-right: 2.4rem;
}

.ms-parent {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.ms-choice {
  display: block;
  width: 100%;
  height: 26px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #aaa;
  text-align: left;
  white-space: nowrap;
  line-height: 26px;
  color: #444;
  text-decoration: none;
  border-radius: 4px;
  background-color: #fff;
}
.ms-choice.disabled {
  background-color: #f4f4f4;
  background-image: none;
  border: 1px solid #ddd;
  cursor: default;
}
.ms-choice > span {
  position: absolute;
  top: 0;
  left: 0;
  right: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  padding-left: 0.8rem;
}
.ms-choice > span.placeholder {
  color: #000000;
}
.ms-choice > span > div {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2.5rem;
}

.ms-drop {
  width: 100%;
  overflow: hidden;
  margin-top: -1px;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 1000;
  background: #fff;
  color: #000;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.ms-drop .ms-no-results {
  display: none;
}
.ms-drop input[type=checkbox] {
  vertical-align: middle;
}
.ms-drop.bottom {
  top: 100%;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}
.ms-drop.top {
  bottom: 100%;
  box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
}
.ms-drop ul {
  overflow: auto;
  margin: 0;
  padding: 5px 8px;
}
.ms-drop ul > li {
  list-style: none;
  display: list-item;
  background-image: none;
  position: static;
}
.ms-drop ul > li label {
  font-weight: normal;
  display: block;
  white-space: nowrap;
}
.ms-drop ul > li label .optgroup {
  font-weight: bold;
}
.ms-drop ul > li.disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
}
.ms-drop ul > li.multiple {
  display: block;
  float: left;
}
.ms-drop ul > li.group {
  clear: both;
}
.ms-drop ul > li.multiple label {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ms-search {
  display: inline-block;
  margin: 0;
  min-height: 26px;
  padding: 4px;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.ms-search input {
  width: 100%;
  height: auto !important;
  min-height: 2.4rem;
  padding: 0 2rem 0 0.5rem;
  margin: 0;
  outline: 0;
  font-family: sans-serif;
  font-size: 1em;
  border: 1px solid #aaa;
  border-radius: 0;
  box-shadow: none;
}
.ms-search,
.ms-search input {
  box-sizing: border-box;
}

.close-multiselect {
  position: absolute;
  top: 0;
  right: 0.8rem;
}

[data-pdcalendarpage] .facets__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
[data-pdcalendarpage] .facets__headline {
  display: inline-block;
  padding: 1rem 0;
  color: #024c7b;
}
[data-pdcalendarpage] .facet--group {
  border-top: 1px solid #ddd;
}
[data-pdcalendarpage] .facet--group:last-child {
  border-bottom: 1px solid #ddd;
}
[data-pdcalendarpage] .facet__header {
  position: relative;
}
[data-pdcalendarpage] .facet__header:after {
  font-family: "Font Awesome 6 Free";
  content: "\f067";
  position: absolute;
  right: 0.9rem;
  top: calc(50% - 0.9rem);
  user-select: none;
  cursor: pointer;
  font-weight: 900;
}
[data-pdcalendarpage] .facet__header:not(.collapsed):after {
  content: "\f068";
}
[data-pdcalendarpage] .facet__header:hover .facet__headline {
  color: #024c7b;
}
[data-pdcalendarpage] .facet__headline {
  padding: 1.6rem 0;
  color: #0090bc;
}
@media (max-width: 1199px) {
  [data-pdcalendarpage] .facet__headline {
    font-size: 1.6rem;
    padding: 1.2rem 0;
  }
}
[data-pdcalendarpage] .facet__list {
  padding: 0.6rem 0.6rem 1.6rem;
}
[data-pdcalendarpage] .facet__list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.6rem;
}

@media (max-width: 991px) {
  .card--pdCalendar {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 576px) {
  .catalog__view--grid .catalog__item:nth-child(odd) {
    padding-right: 1rem;
  }
  .catalog__view--grid .catalog__item:nth-child(even) {
    padding-left: 1rem;
  }
}
.catalog__view--grid .catalog__item--card {
  height: calc(100% - 2rem);
}
.catalog__view--grid .catalog__item--card:before {
  content: "";
  height: 5px;
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
}
.catalog__view--grid .catalog__item--card.card-info:before {
  background-color: #76C3D4;
}
.catalog__view--grid .catalog__item--card.card-primary:before {
  background-color: #024c7b;
}
.catalog__view--grid .catalog__item--card.card-danger:before {
  background-color: #d13138;
}
.catalog__view--grid .catalog__item--card.card-warning:before {
  background-color: #fdcd06;
}
.catalog__view--grid .catalog__item--card.card-secondary:before {
  background-color: #40d0a5;
}
@media (max-width: 767px) {
  .catalog__view--grid .catalog__item--card.card-info:before, .catalog__view--grid .catalog__item--card.card-primary:before, .catalog__view--grid .catalog__item--card.card-danger:before, .catalog__view--grid .catalog__item--card.card-warning:before, .catalog__view--grid .catalog__item--card.card-secondary:before {
    background: none;
  }
}
.catalog__view--grid .catalog__item--link {
  height: 100%;
}
.catalog__view--grid .card__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 14.6rem;
}
.catalog__view--grid .catalog__item--icon {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.catalog__view--list .catalog__item {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}
.catalog__view--list .card__content {
  display: flex;
  padding-top: 2.4rem;
}
@media (max-width: 767px) {
  .catalog__view--list .card__content {
    flex-direction: column;
  }
}
.catalog__view--list .card__headline {
  width: 66.6%;
  padding-right: 1.6rem;
}
.catalog__view--list .catalog__item--meta {
  width: 33.3%;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .catalog__view--list .catalog__item--meta {
    width: 100%;
  }
}
.catalog__view--list .catalog__item--icon {
  padding-left: 1.6rem;
  margin-left: auto;
  text-align: right;
  padding-right: 0;
}

.catalog__item--icon img {
  max-width: 4.8rem;
}

.catalog__results .card {
  margin-bottom: 2rem;
}

.catalog__controls {
  padding-bottom: 1.6rem;
  padding-top: 3.2rem;
}
.catalog__controls .fas {
  font-size: 2.2rem;
  color: #666;
  transition: all 0.5s ease;
}
.catalog__controls .fas:hover {
  color: #d4d4d4;
}
.catalog__controls .active .fas {
  color: #d4d4d4;
}
@media (max-width: 1199px) {
  .catalog__controls {
    padding-top: 0;
  }
}

.catalog__item--location {
  color: #656565;
}
.catalog__item--location p {
  padding: 0;
}

.catalog__item--link:hover {
  background-color: #f2f2f2;
}

.pdCaledarTabs ul.nav-tabs {
  padding-left: 0;
}
.pdCaledarTabs ul.nav-tabs .nav-link {
  border-radius: 0;
  padding: 1rem 1.6rem;
  margin-bottom: -1px;
  border: 1px solid transparent;
}
.pdCaledarTabs ul.nav-tabs .nav-link.active {
  border: 1px solid #ccc;
  border-top: 2px solid #024c7b;
  border-bottom: 1px solid #fff;
}
.pdCaledarTabs ul.nav-tabs .nav-link.active a {
  color: #666;
}
.pdCaledarTabs ul.nav-tabs .nav-link.active:hover {
  border-top: 2px solid #3c8dbc;
}
.pdCaledarTabs ul.nav-tabs .nav-link:hover {
  border-color: none;
}

@media (min-width: 1200px) {
  [data-event-detail] .banner-col-lg {
    max-width: 87.5rem;
  }
}
[data-event-detail] .event-panel__filter {
  box-shadow: none;
  border: 0 none;
  background: transparent;
  margin-bottom: 0;
  border-bottom: 1px solid #ddd;
}
@media (min-width: 1200px) {
  [data-event-detail] .banner-col-sm {
    width: calc(100% - 87.5rem);
  }
}
@media (min-width: 1200px) {
  [data-event-detail] .content-col-lg {
    width: 84.4rem;
    max-width: 84.4rem;
    flex: 0 0 84.4rem;
  }
}
@media (min-width: 1200px) {
  [data-event-detail] .content-col-sm {
    width: calc(100% - 84.4rem);
    max-width: calc(100% - 84.4rem);
    flex: 0 0 calc(100% - 84.4rem);
  }
}
[data-event-detail] .nav-tabs {
  border-bottom: none;
}
[data-event-detail] .interior-nav__link span {
  pointer-events: none;
}

.product-event__no-margin {
  padding-bottom: 0;
}

.banner--event-detail {
  padding-top: 3.2rem;
}

.brand__wrapper--event {
  padding-top: 1.6rem;
}

.banner {
  width: 100%;
  background-color: #fff;
}

.banner__info {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 1.6rem;
  width: 100%;
}
.banner__info .banner__title {
  font-size: 3.2rem;
  padding-bottom: 0;
  color: #fff;
  padding: 0;
}
.banner__info .banner__subtitle {
  color: #fff;
  padding: 0;
  padding-bottom: 0.4rem;
  font-size: 2rem;
}
@media (max-width: 991px) {
  .banner__info {
    padding: 3.2rem 2.4rem;
  }
}

.banner__register {
  color: #d13138;
  text-align: center;
  width: 100%;
  display: block;
}
@media (max-width: 991px) {
  .banner__register {
    padding: 2.4rem 0;
  }
}

.banner__buttonWrapper {
  padding-bottom: 0.8rem;
}

.toggle-show-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.8rem;
  margin-top: 0;
}
@media (max-width: 991px) {
  .toggle-show-container {
    max-width: 300px;
  }
}
.toggle-show-trigger {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  font-weight: normal;
  outline: none;
}
.toggle-show-trigger i {
  margin-left: 0.6rem;
  transition: 0.2s all;
}
.toggle-show-trigger[aria-expanded=true] i {
  transform: rotate(180deg);
}
.toggle-show-trigger:active, .toggle-show-trigger:focus {
  outline: none;
}
.toggle-show-panel {
  position: absolute;
  background: #ffffff;
  z-index: 10;
  width: calc(100% - 30px);
  display: none;
}
.toggle-show-panel[aria-hidden=false] {
  display: block;
  box-shadow: 2px 2px 8px 0px rgba(50, 50, 50, 0.75);
}
.toggle-show-panel .register-list {
  list-style: none;
  padding: 0;
}
.toggle-show-panel .register-list-item a {
  padding: 0.8rem;
  display: block;
  color: #666;
}
.toggle-show-panel .register-list-item a:hover {
  background-color: #f2f2f2;
}

.banner-col-lg {
  display: flex;
  align-items: center;
}

.banner-col-sm {
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .banner-col-sm {
    display: block;
  }
}

.banner__bg {
  min-height: 15rem;
  display: flex;
  align-items: center;
  width: 100%;
  background-size: cover;
}
@media (max-width: 991px) {
  .banner__bg {
    background-image: none !important;
    background-color: #024c7b;
  }
}
@media (min-width: 992px) {
  .banner__bg {
    height: 1px;
  }
}

.product-event {
  background-color: #fff;
}

.product-event__card {
  padding: 3.2rem 3.2rem 4.8rem;
}
.product-event__card .tab-content ul {
  padding-left: 4rem;
}

.product-event__competency {
  display: inline-block;
  list-style: none;
  padding-left: 0;
}

.product-event__competencyItem {
  display: inline;
}

.product-event__competencyImg {
  padding: 0 0.4rem 0.4rem;
}

.product-event__sidebar {
  padding-top: 7.2rem;
  padding-bottom: 6.4rem;
}
@media (max-width: 991px) {
  .product-event__sidebar {
    padding: 0 3.2rem 1.6rem;
  }
}

.product-event__list {
  list-style: none;
  padding: 1.6rem 1.6rem 6.4rem 0;
}
@media (max-width: 991px) {
  .product-event__list {
    padding-bottom: 3.2rem;
  }
}
.product-event__list:not(first-of-type) {
  padding-top: 0;
}

.product-event__item {
  padding: 1.6rem 0;
  border-bottom: 1px solid #ccc;
}
.product-event__item:first-of-type {
  padding-top: 0;
}

.product-event__listHeadline {
  padding-top: 1rem;
  text-transform: uppercase;
  color: #666;
}

.product-event__panel {
  margin-bottom: 1.2rem;
  padding: 0.4rem 1.2rem 0.8rem;
  border: 0.1rem solid #ddd;
}

.session-link, .session-link:visited {
  color: #0090bc;
}

.soa-sidebar-filter__header {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

a.resetAllFilters:not([href]) {
  color: #0090bc;
}

.soa-sidebar-filter__headline {
  padding: 0;
  color: #024c7b;
}

.event-panel__button {
  display: block;
  border: 0 none;
  box-shadow: none;
  border: 0 none;
  background: transparent;
  padding: 0.8rem 0;
  text-align: left;
  width: 100%;
  position: relative;
}
.event-panel__button:hover .event-panel__title {
  color: #2a6496;
}
.event-panel__button:after {
  font-family: "Font Awesome 6 Free";
  content: "\f067";
  position: absolute;
  right: 0.9rem;
  top: calc(50% - 0.9rem);
  user-select: none;
  cursor: pointer;
  font-weight: 900;
  color: #666;
}
.event-panel__button:not(.collapsed):after {
  content: "\f068";
}

.event-panel__title {
  font-size: 1.8rem;
  padding-top: 0;
  color: #0090bc;
}

.event-panel__body {
  padding-left: 0;
  list-style: none;
}

.filter__label {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.filter__checkbox {
  flex-shrink: 0;
}

.modal--sessions .modal-title {
  padding-top: 0;
  padding-bottom: 0;
}
.modal--sessions .modal-body,
.modal--sessions .modal-header {
  padding: 2rem;
}
@media (min-width: 576px) {
  .modal--sessions .modal-dialog {
    max-width: 60rem;
  }
}

@media (max-width: 991px) {
  .soa-program-interior-nav {
    border-left: 0.1rem solid #bdbaba;
    border-right: 0.1rem solid #bdbaba;
  }
}

.interior-nav__list {
  display: flex;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
}
@media (max-width: 991px) {
  .interior-nav__list {
    display: block;
  }
}

.interior-nav__item {
  position: relative;
  background: #f8f8f8;
  text-align: center;
  flex: 1 0 auto;
  transition: all 0.3s ease;
}
.interior-nav__item:hover {
  background: #196499;
}
.interior-nav__item:hover .interior-nav__link {
  color: #fff;
  transition: none;
}
.interior-nav__item.active, .interior-nav__item.active-program-page {
  background: #00538e !important;
  transition: all 0.3s ease;
}
.interior-nav__item.active .interior-nav__link, .interior-nav__item.active-program-page .interior-nav__link {
  color: #fff;
  font-weight: 700;
}
.interior-nav__item.active:hover, .interior-nav__item.active-program-page:hover {
  background: #196499 !important;
}
.interior-nav__item.active:hover:after, .interior-nav__item.active-program-page:hover:after {
  border-top-color: #196499;
}
@media (min-width: 992px) {
  .interior-nav__item.active:after, .interior-nav__item.active-program-page:after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 0.8rem);
    width: 0;
    height: 0;
    margin-top: -1px;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid #00538e;
    z-index: 1;
    transition: all 0.3s ease;
  }
}
.interior-nav__item:nth-last-child(-n+1):first-child, .interior-nav__item:nth-last-child(-n+1):first-child ~ li {
  display: none;
}
@media (min-width: 992px) {
  .interior-nav__item:nth-last-child(n+2):nth-last-child(-n+2):first-child, .interior-nav__item:nth-last-child(n+2):nth-last-child(-n+2):first-child ~ li {
    width: 49.5%;
  }
  .interior-nav__item:nth-last-child(n+3):nth-last-child(-n+3):first-child, .interior-nav__item:nth-last-child(n+3):nth-last-child(-n+3):first-child ~ li {
    width: 33%;
  }
  .interior-nav__item:nth-last-child(n+4):nth-last-child(-n+4):first-child, .interior-nav__item:nth-last-child(n+4):nth-last-child(-n+4):first-child ~ li {
    width: 24.5%;
  }
  .interior-nav__item:nth-last-child(n+5):nth-last-child(-n+5):first-child, .interior-nav__item:nth-last-child(n+5):nth-last-child(-n+5):first-child ~ li {
    width: 19.5%;
  }
}
@media (max-width: 991px) {
  .interior-nav__item {
    display: block;
    width: 100%;
    border-bottom: 0.1rem solid #bdbaba;
    background: #fff;
  }
  .interior-nav__item:first-child {
    border-top: 0.1rem solid #bdbaba;
  }
  .interior-nav__item .interior-nav__link {
    min-height: 2rem;
  }
}

.interior-nav__link,
.interior-nav__link:visited {
  display: flex;
  position: relative;
  min-height: 4.8rem;
  color: #00538e;
  transition: all 0.5s ease;
  justify-content: center;
  align-items: center;
  height: 1px;
}
.interior-nav__link.active,
.interior-nav__link:visited.active {
  background: #00538e !important;
  color: #fff;
  font-weight: 700;
}
@media (min-width: 992px) {
  .interior-nav__link.active:after,
  .interior-nav__link:visited.active:after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 0.8rem);
    width: 0;
    height: 0;
    margin-top: -1px;
    border-left: 0.8rem solid transparent;
    border-right: 0.8rem solid transparent;
    border-top: 0.8rem solid #00538e;
    z-index: 1;
    transition: all 0.3s ease;
  }
}
@media (max-width: 991px) {
  .interior-nav__link,
  .interior-nav__link:visited {
    padding: 0.8rem;
  }
}