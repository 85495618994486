$color-primary: orange;

//Color Palette
$primary-blue-1: #024c7b;
$secondary-blue: #76C3D4;
$secondary-teal: #40d0a5;
$primary-red: #d13138;
$primary-yellow: #fdcd06;
$secondary-green: #babf33;
$secondary-orange: #e27e26;
$secondary-grey: #bdbaba;
//to avoid possibly maddening spelling bug
$secondary-gray: #bdbaba;
$tertiary-green: #43B5A3;
$tertiary-navy: #209FB6;
$headline-color: #1e1e1e;
$body-copy-color: #666;
$cta-arrow-bg-hover: #247893;

// Custom Colors
$dark-grey: #656565;
$mid-grey: #ccc;
$off-white: #f2f2f2;
$account-hover: #0b6299;
$light-grey:#f8f8f8;
$dark-icons: #666;
$light-icons: #d4d4d4;
$footer-link-grey: #918a7e;
$grey-border: #ccc;
$grey-dark: #ddd;
$grey-warm: #ececec;
$grey-warm-darker: #E5E5E5;
$icon-blue: #348ac7;
$table-hover-grey: #f5f5f5;
$tab-hover: #3c8dbc;
$module-box-bg: #eef7f9;
$seminar-box-bg: #c8e5ec;
$table-header-grey: #a1a19f;

// Link Colors
$link-default: #0090bc;
$link-hover: #2a6496;
$link-visited: #020f7b;
$link-red: #c71d28;

// Other
$header-text: #333;
$header-text-light: #555;
$filter-header-color: #00497a;
$filter-subheader-color: #0091bb;
$filter-checkbox-text: #3a99fc;
$filter-icon-color: #313033;
$text-muted: #C3C0C0;
$faded-orange: rgb(208, 191, 165);

$grey-opaque: rgba(189,186,186,0.2);

//Other variables
$padding-base: 1.6rem;

//FileType colors
$metro-blue: #2b5797;
$metro-green: #00a300;
$metro-orange: #da532c;