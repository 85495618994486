.main {
	a {
		&:not(.results__link):not(.card__headline__link) {
			&[href$=".pdf"],
			&[href$=".doc"],
			&[href$=".docx"],
			&[href$=".xls"],
			&[href$=".xlsx"],
			&[href$=".xlsm"],
			&[href$=".ppt"],
			&[href$=".pptx"],
			&[href$=".zip"] {
				&:after {
					padding-left:0.4em;
					font-size:0.9em;
					display:inline-block;
					transform:translateY(-0.1em);
					font-family: 'Font Awesome 6 Free';
					font-weight:400;
				}
			}
	
			&[href$=".pdf"] {
				&:after {
					content: '\f1c1';
					//color: $primary-red;
				}			
			}
			&[href$=".doc"],
			&[href$=".docx"] {
				&:after {
					content: '\f1c2';
					//color: $metro-blue;
				}			
			}
			&[href$=".xls"],
			&[href$=".xlsm"],
			&[href$=".xlsx"] {
				&:after {
					content: '\f1c3';
					//color: $metro-green;
				}			
			}
			&[href$=".ppt"],
			&[href$=".pptx"] {
				&:after {
					content: '\f1c4';
					//color: $metro-orange;
				}			
			}
			&[href$=".zip"] {
				&:after {
					content: '\f1c6';
					//color: $color-primary;
				}
			}
		}
	}
	.soa-sidebar-content {
		a {
			&[href$=".pdf"],
			&[href$=".doc"],
			&[href$=".docx"],
			&[href$=".xls"],
			&[href$=".xlsx"],
			&[href$=".xlsm"],
			&[href$=".ppt"],
			&[href$=".pptx"],
			&[href$=".zip"] {
				&:after {
					display:none !important;
				}
			}
		}
	}
}